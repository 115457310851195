
import { mapGetters } from 'vuex';
// @group document

export default {
  name: 'PageComponent',
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      show: false
    };
  },
  computed: {
    ...mapGetters({
      isLogoWhite: 'ui/isLogoWhite'
    })
  },
  mounted() {
    // Useful resource to understand this line:
    // https://dmitripavlutin.com/vue-next-tick/

    this.$nextTick(() => (this.show = true));
    if (!this.data?.content?.sections.length > 0) {
      return;
    }

    if (
      this.data.content.sections[0]._type === 'videoHero' ||
      this.data.content.sections[0]._type === 'detailPageHero'
    ) {
      this.$store.commit('ui/setLogoColor', true);
    } else {
      this.$store.commit('ui/setLogoColor', false);
    }
  }
};
